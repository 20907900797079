<template>
  <div class="ad-container" v-if="image_path">
    <a :href="link">
      <img :src="image_path" alt="" class="image-ad rounded-xl"/>
    </a>
    <p class="ad">Ad</p>
  </div>
</template>

<script>
import axios from '@/axios.js';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
export default {
  name: 'Advertisement',
  props: {
    siteName: {
      required: true,
    },
    idHarmony: {
      required: true,
    },
    idUser: {
      required: true,
    },
    ageId: {
      required: true,
    },
    genderId: {
      required: true,
    },
    creatorTypeId: {
      required: true,
    },
  },

  data() {
    return {
      image_path: null,
      currentIndex: 0,
      code_Ad: null,
      base_url: null,
      custom_params: null,
      ad_language: 'ES'
    };
  },

  computed: {
    link() {
      // Parámetros obligatorios
      const mandatoryParams = {
        aff_sub: 'Ads1Left',
        aff_sub2: this.idUser,
        aff_sub3: this.idHarmony,
        aff_sub5: this.code_Ad,
        source: `${this.siteName}.${this.idUser}.${this.idHarmony}.Ads1Left`,
      };

      const allParams = new URLSearchParams({
        ...this.custom_params,
        ...mandatoryParams // Los mandatorios tienen prioridad
      });
      return `${this.base_url}?${allParams.toString()}`;
    }
  },
  async mounted() {
    await this.getAdRandom();

    // Carga el script de anuncio de chat
    const script = document.createElement('script');
    script.src = `https://www.crxcr1.com/im_jerky?lang=en&mode=prerecorded&outlinkUrl=https://t.acrsmartcam.com/328848/3664/0?aff_sub=Adschat&aff_sub2=${this.idUser}&aff_sub3=${this.idHarmony}&bo=2779,2778,2777,2776,2775&source=${this.siteName}.${this.idUser}.${this.idHarmony}.Ads1Left&po=6533&aff_sub5=SF_006OG000004lmDN`;
    document.body.appendChild(script);
    this.initImpressionTracker();
  },


  methods: {
    async getAdRandom() {
      const response = await axios.post('/get-ads-random', {
        age_id: this.ageId,
        gender_id: this.genderId,
        creator_type_id: this.creatorTypeId,
      });

      if (response.status == 200) {
        this.code_Ad = response.data.unique_code;
        this.ad_language = response.data.lang;
        this.image_path = response.data.image_path;
        this.base_url = response.data.base_url;
        this.custom_params = response.data.custom_params;
      }

    },

    async registerImpression() {
      try {
        let visitorId = localStorage.getItem('visitorId');

        if (!visitorId) {
          const fpPromise = FingerprintJS.load();
          const fp = await fpPromise;
          const result = await fp.get();
          visitorId = result.visitorId;
          localStorage.setItem('visitorId', visitorId);
        }

        const response = await axios.post('/register-impression', {
          banner_code: this.code_Ad,
          fingerprint: visitorId,
          site_id: this.idHarmony,
        });

        if (response.status == 200) {
          console.log('Impresión registrada con fingerprint');
        }
      } catch (error) {
        console.error('Error al registrar impresión:', error);
      }
    },

    initImpressionTracker() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.registerImpression();
            observer.unobserve(entry.target);
          }
        });
      }, { threshold: 0.7 });

      observer.observe(this.$el);
    }
  }
}
</script>

<style scoped>
.ad-container {
  width: 100%;
  max-width: 320px; 
  margin: 0 auto;
  position: relative;
}

.full-height-iframe {
  width: 100%;
  height: 100%;
  min-height: 250px; /* Altura mínima original */
  border: 0;
}
.ad{
  color:white;
  font-size: 13px;
}

.image-ad{
  width: 100%;
  height: 100%;
}

/* Opcional: Ajuste para diferentes contextos de contenedor */
@media (min-width: 768px) {
  .ad-container {
    height: 100%; /* Hereda altura del padre */
    max-height: 600px; /* Límite máximo si es necesario */
  }
}
</style>